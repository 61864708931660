import React, {Component} from 'react';
import {API, Storage} from 'aws-amplify';
import LoaderButton from '../components/LoaderButton';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import config from '../config';
import './Books.css';
import {s3Upload} from '../libs/awsLibs';

export default class Books extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            book: null,
            bookName: '',
            attachmentURL: null,
            isLoading: null,
            isDeleting: null
        };

    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const book = await this.getBook();
            const {bookName, attachment} = book;

            if (attachment) {
                attachmentURL = await Storage.vault.get(attachment);
            }

            this.setState({
                book,
                bookName,
                attachmentURL
            });
        } catch (e) {
            alert(e);
        }
    }

    getBook() {
        return API.get('books', `/books/${this.props.match.params.id}`);
    }

    validateForm() {
        return this.state.bookName.length > 0;
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, '');
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFileChange = event => {
        this.file = event.target.files[0];
    };

    saveNote(book) {
        return API.put('books', `/books/${this.props.match.params.id}`, {
            body: book
        });
    }

    handleSubmit = async event => {
        let attachment;

        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({isLoading: true});

        try {
            if (this.file) {
                attachment = await s3Upload(this.file);
            }

            await this.saveNote({
                bookName: this.state.bookName,
                attachment: attachment || this.state.book.attachment
            });
            this.props.history.push('/');
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    deleteBook() {
        return API.del('books', `/books/${this.props.match.params.id}`);
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            'Are you sure you want to delete this book?'
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteBook();
            this.props.history.push('/');
        } catch (e) {
            alert(e);
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="Books">
                {this.state.book &&
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="bookName">
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.bookName}
                            componentClass="textarea"
                        />
                    </FormGroup>
                    {this.state.book.attachment &&
                    <FormGroup>
                        <ControlLabel>Attachment</ControlLabel>
                        <FormControl.Static>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.state.attachmentURL}
                            >
                                {this.formatFilename(this.state.book.attachment)}
                            </a>
                        </FormControl.Static>
                    </FormGroup>}
                    <FormGroup controlId="file">
                        {!this.state.book.attachment &&
                        <ControlLabel>Attachment</ControlLabel>}
                        <FormControl onChange={this.handleFileChange} type="file"/>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                </form>}
            </div>
        );
    }
}
