import React, {Component} from 'react';
import {PageHeader, ListGroup, ListGroupItem} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {API} from 'aws-amplify';
import './Home.css';
import {Link} from 'react-router-dom';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            books: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const books = await this.notes();
            this.setState({books});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    notes() {
        return API.get('books', '/books');
    }

    renderNotesList(books) {
        return [{}].concat(books).map(
            (book, i) =>
                i !== 0
                    ? <LinkContainer
                        key={book.bookId}
                        to={`/books/${book.bookId}`}
                    >
                        <ListGroupItem header={book.bookName.trim().split('\n')[0]}>
                            {'Created: ' + new Date(book.createdAt).toLocaleString()}
                        </ListGroupItem>
                    </LinkContainer>
                    : <LinkContainer
                        key="new"
                        to="/books/new"
                    >
                        <ListGroupItem>
                            <h4>
                                <b>{'\uFF0B'}</b> Create a new note
                            </h4>
                        </ListGroupItem>
                    </LinkContainer>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>MyBooks</h1>
                <p>A simple book app</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Login
                    </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
                    </Link>
                </div>
            </div>
        );
    }

    renderNotes() {
        return (
            <div className="books">
                <PageHeader>Your Books</PageHeader>
                <ListGroup>
                    {!this.state.isLoading && this.renderNotesList(this.state.books)}
                </ListGroup>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
            </div>
        );
    }
}
