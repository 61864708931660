import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import AppliedRoute from './components/AppliedRoute';
import Signup from './containers/Signup';
import NewBook from './containers/NewBook';
import Books from './containers/Books';

export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps}/>
        <AuthenticatedRoute path="/books/new" exact component={NewBook} props={childProps}/>
        <AuthenticatedRoute path="/books/:id" exact component={Books} props={childProps}/>

        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;
