import React, {Component} from 'react';
import {FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import {API} from 'aws-amplify';
import LoaderButton from '../components/LoaderButton';
import config from '../config';
import './NewBook.css';
import {s3Upload} from '../libs/awsLibs';

export default class NewBook extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            bookName: ''
        };
    }

    validateForm() {
        return this.state.bookName.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFileChange = event => {
        this.file = event.target.files[0];
    };

    handleSubmit = async event => {
        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({isLoading: true});

        try {
            const attachment = this.file
                ? await s3Upload(this.file)
                : null;

            let note = {
                bookName: this.state.bookName,
                attachment
            };

            await this.createNote(note);
            this.props.history.push('/');
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    createNote(note) {
        return API.post('books', '/books', {
            body: note
        });
    }

    render() {
        return (
            <div className="NewBook">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="bookName">
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.bookName}
                            componentClass="textarea"
                        />
                    </FormGroup>
                    <FormGroup controlId="file">
                        <ControlLabel>Attachment</ControlLabel>
                        <FormControl onChange={this.handleFileChange} type="file"/>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
