export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "eu-central-1",
        BUCKET: "mybooks-books-titleimage"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://r1awjfm9rl.execute-api.eu-central-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_brUeiWBqr",
        APP_CLIENT_ID: "68u8frnijib3o1edfifin9o0g6",
        IDENTITY_POOL_ID: "eu-central-1:dbfb2c6a-6fb6-408f-8fb2-24ce99ad7a3f"
    }
};
